import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaDribbble, FaTwitter, FaTwitterSquare, FaXRay } from "react-icons/fa";


const SplashFooter = () => {
    return (
        <footer className="footer-area splash-footer">
            <div className="container">
                <div className="footer-bottom">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                        <div className="footer-copyright">
                            <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by healthSOL.</span>
                        </div>
                        </div>
                        <div className="col-lg-2">
                        <ul className="footer-social list-unstyled">
                            <li><a href="https://x.com/gethealthsol"><FaTwitter /></a></li>
                            
                        </ul>
                        </div>
                        <div className="col-lg-5">
                        <div className="footer-bottom-link">
                            <ul className="list-unstyled">
                                <li>Hold 1 SOL</li>
                                <li>Save 1 Soul</li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default SplashFooter;